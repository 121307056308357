<template>
  <div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" @disabled=true>
      <div style="padding-bottom: 3rem">
        <div class="top">
          <!-- 通知 -->
          <div class="notice" @click="getNotices">
            <div></div>
            <div class="notice_text">
              <span class="iconfont icon-tongzhi icon" @click="getNotices"></span>
              <div class="titles">通知</div>
            </div>
          </div>
          <div class="avatar">
            <!-- 头像 -->
            <div class="imgbox">
              <img style="width: 100%" v-if="this.photo" :src="oss_url + this.photo" @click="ImagePreview" />
              <img style="width: 100%" v-else src="../../assets/img/nohead.png" alt="" />
            </div>
            <div class="avatar-font">
              <p class="p1">
                <span>{{ this.user_name }}</span>
                <!-- <van-icon name="sort" style="font-size: 5vw" @click="cut" /> -->
                <img src="../../assets/member/icon_qihuan@2x.png" @click="cut">
              </p>
              <p class="p2" v-if="this.mobile">
                {{ this.mobile }}
              </p>
              <p class="p2" v-else>暂无获取到手机号</p>
            </div>

            <!-- <img class="top-img" src="../../assets/img/fengcai.png" alt="" /> -->
          </div>
          <Datepicker class="time" @func="getMsgFormSon"></Datepicker>
        </div>
        <div class="card" :style="user_type == 'class_admin' ? 'margin-top:7rem' : 'margin-top:8rem'
          ">
          <div class="title">
            <span class="one">到校汇总</span>
            <van-popover v-model="showPopover" trigger="click" :actions="actions" placement="top" theme="dark">
              <template #reference>
                <img src="../../assets/img/wenhao.png" alt="" />
              </template>
            </van-popover>
            <div @click="onRefresh()" style="display: inline-block">
              <span class="two">刷新</span>
              <van-icon name="replay" />
            </div>

            <router-link to="/teameasure">
              <b v-if="user_type == 'class_admin'">
                请假审批
                <van-icon name="arrow" />
              </b>
              <i :class="user_type == 'class_admin' ? 'circle' : 'nohave'"></i>
            </router-link>
          </div>
          <!-- 选择器 -->
          <van-field readonly clickable name="picker" :value="value" label="" placeholder="" @click="showPicker = true"
            v-if="user_type === 'class_admin'" />
          <van-field readonly clickable name="picker" :value="value" label="" placeholder="" @click="showPicker = true"
            v-if="user_type === 'school_admin'" style="float: right; margin-top: -2rem" />
          <div class="sanjiao" @click="showPicker = true" :class="user_type === 'class_admin' ? 'sanjiao' : 'change'">
          </div>

          <!-- 卡片 -->
          <div class="card-1">
            <van-tabs v-model="active" color="#5380FF" @click="onClick">
              <van-tab>
                <template #title>
                  <p class="tit">全部(人)</p>
                  <p class="txt">{{ list.total_count }}</p>
                </template>
              </van-tab>
              <van-tab>
                <template #title>
                  <p class="tit">到校(人)</p>
                  <p class="txt">{{ list.attend_count }}</p>
                </template>
              </van-tab>
              <van-tab>
                <template #title>
                  <p class="tit">迟到(人)</p>
                  <p class="txt">{{ list.lateCount }}</p>
                </template>
              </van-tab>
              <van-tab>
                <template #title>
                  <p class="tit">未到(人)</p>
                  <p class="txt">{{ list.no_attend }}</p>
                </template>
              </van-tab>
            </van-tabs>
          </div>
          <!-- 列表 -->

          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <Table-vant :option="option" :tableData="CtableData"></Table-vant>
          </van-list>
        </div>
        <van-popup v-model="showPicker" position="bottom">
          <van-picker show-toolbar title="选择班级" ref="picker" :columns="columns" @confirm="onConfirm"
            @cancel="showPicker = false" value-key="name" />
        </van-popup>
      </div>
      <Foot></Foot>
    </van-pull-refresh>
  </div>
</template>
<script>
import Datepicker from "../../components/Datepicker.vue";
import { teacherIndex } from "../../api/teacher/index";
import Foot from "../../components/Foot.vue";
import TableVant from "../../components/MyTable.vue";
import { getClass } from "../../api/leavelist";
import { getMobile, getUserType, getUserName, getUserPhoto } from "../../utils/user";
import { ImagePreview } from 'vant';

export default {
  components: { Foot, Datepicker, TableVant },
  name: "MyTable",
  data() {
    return {
      t_ime: {}, // 插件传来时间
      list: [],
      user_type: '',
      datetime: {
        start_time: "",
        end_time: "",
        page: 1,
        classes_id: "",
        type: "",
      },
      value: "全部班级",
      columns: [
        {
          id: 0,
          name: "全部班级",
          children: [
            {
              name: "",
            },
          ],
        },
      ],
      selected: "",
      active: 0,
      showPicker: false,
      tableData: [], //获取到的所有数据
      CtableData: [], //卡片的赋值表数据
      oss_url: process.env.VUE_APP_OSS_URL,
      option: {
        column: [
          {
            label: "班级",
            tableDataprop: "class_name",
            status: "status_str",
          },
          {
            label: "姓名",
            tableDataprop: "user_name",
            status: "status_str",
          },
          {
            label: "打卡时间",
            tableDataprop: "attendance_time",
            status: "status_str",
          },
          {
            label: "体温",
            tableDataprop: "temperature",
            status: "status_str",
          },
        ],
      },
      page: 1, //显示第一页
      loading: false, // 是否正在加载
      finished: false,
      refreshing: false,
      newobj: [], //年纪班级级联选择器
      //气泡弹出框
      showPopover: false,
      ycdata: [], //年纪班级对应数据
      actions: [{ text: "此处仅统计早上的考勤数据" }],
      mobile: '',
      user_name: '',
      photo: ''
    };
  },
  created() {
    this.user_type = getUserType();
    this.user_name = getUserName();
    this.photo = getUserPhoto();
    // 获取到年级班级
    getClass("mobile/common/gradeClasses").then((res) => {
      this.ycdata = res.data;
      res.data.forEach((item) => {
        this.columns.push(item);
      });
    });
    this.mobile = getMobile()
  },
  methods: {
    // 获取考勤列表
    getlist() {
      teacherIndex(this.datetime).then((res) => {
        this.list = res.data;
        if (res.data.length === 0) {
          this.finished = true;
          this.loading = false;
          return;
        }
        this.list = res.data;
        this.CtableData = this.CtableData.concat(res.data.rows);
        this.loading = false;
      });
    },
    // 获取时段
    getMsgFormSon(star, end, time) {
      this.page = 1;
      this.t_ime = {
        start_time: star,
        end_time: end,
      };
      this.datetime.start_time = time;
      this.datetime.end_time = time;
      this.datetime.page = this.page;
      this.CtableData = [];
      this.loading = true;
      this.finished = false;
      this.getlist();
    },
    // 切换身份
    cut() {
      this.$store.commit("RESET_STATE");
      this.$router.push("/choose");
    },
    //弹出窗确定选择年纪班级
    onConfirm(value) {

      let cid = this.$refs.picker.getValues()[1].id

      let showtxt = "";
      for (let i = 0; i < value.length; i++) {
        if (value[i]) {
          showtxt += value[i] + "-";
        } else {
          showtxt += value[i];
        }
      }
      showtxt = showtxt.slice(0, -1);
      this.value = showtxt;
      this.showPicker = false;

      this.CtableData = [];
      this.loading = true;
      this.finished = false;
      this.datetime.page = 1;

      if (value[0] === "全部班级") {
        this.datetime.classes_id = '';
        this.getlist();
        return;
      }
      this.ycdata.forEach((i) => {
        if (i.children.length >= 1) {
          i.children.forEach(async (c) => {
            if (c.id === cid) {
              this.datetime.classes_id = c.id;
              this.CtableData = [];
              this.list = {};
              //得到具体班级的人数
              this.getlist();
            }
          });
        }
      });

    },
    //点击卡片不同状态的列表人数
    onClick(index) {
      if (index === 3) {
        index = 9;
      }
      this.loading = true;
      this.finished = false;
      this.datetime.page = 1;
      this.datetime.type = index;
      this.CtableData = [];
      teacherIndex(this.datetime).then((res) => {
        if (res.data.rows.length === 0) {
          this.finished = true;
          this.loading = false;
          return;
        }
        this.list = res.data;
        this.CtableData = this.CtableData.concat(res.data.rows);
        this.loading = false;
      });
    },
    //加载刷新
    onLoad() {
      setTimeout(() => {
        this.datetime.page++;
        teacherIndex(this.datetime).then((res) => {
          this.refreshing = false;
          if (res.data.rows.length === 0) {
            this.finished = true;
            this.loading = false;
            return;
          }
          this.CtableData = this.CtableData.concat(res.data.rows);
          this.loading = false;
        });
      });
    },

    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.datetime.page = 0;
      this.CtableData = [];
      this.onLoad();
    },
    getNotices() {
      this.$router.push("/noticeteach");
    },
    ImagePreview() {
      ImagePreview([
        this.oss_url + this.photo,
      ]);
    }
  },
};
</script>

<style lang="less" scoped>
//
.top-img {
  width: 5rem;
  position: absolute;
  right: 1rem;
  top: 4rem;
}

/* 蓝色背景图 */
.top {
  background: url("../../assets/img/Group 427319265.png");
  background-repeat: no-repeat;
  background-size: 100% 100%; // 等比例拉伸
  widows: 100%;
  height: 13rem;
}

/* 头像框 */
.avatar {
  .imgbox {
    width: 21vw;
    height: 21vw;
    border-radius: 3.3rem;
    overflow: hidden;
  }

  .avatar-font {
    margin-left: 2vw;
    width: 34vw;

    .p1 {
      width: 40vw;
      color: white;
      font-weight: bold;
      font-size: 5vw;
      display: flex;
      align-items: center;

      img {
        width: 13vw;
        height: 5vw;
        margin-left: 3vw;
      }

      span {
        width: 60%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis
      }
    }

    .p2 {
      color: #ffffffba;
      font-size: 3.5vw;
      margin-top: -1.5vh;
    }
  }

  display: flex;
  padding: 2.25rem 0 0 1.125rem;
  align-items: center;
}

//卡片标题
.title .one {
  font-size: 4.5vw;
  font-weight: 500;
}

.title .two {
  margin-left: 5vw;
  margin-right: 0.6vw;
  color: #999;
  font-size: 0.88rem;
}

.title b {
  font-weight: 400;
  display: block;
  float: right;
  width: 5.5rem;
  height: 1.8rem;
  line-height: 1.8rem;
  text-align: center;
  background-color: #5380ff;
  color: #fff;
  border-radius: 0.23rem;
  font-size: 0.88rem;
}

.title .circle {
  display: inline-block;
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 0.35rem;
  background: #fe6b4a;
  position: absolute;
  top: -0.25rem;
  right: -0.15rem;
}

i.van-icon.van-icon-replay {
  color: #999;
}

.title img {
  width: 4vw;
  margin-left: 1.5vw;
}

//卡片展示
.card {
  // height: 89vh;
  // margin-top: 8rem;
  width: 90%;
  margin-left: 50%;
  transform: translate(-50%);
  border-radius: 0.5rem;

  .card-1 {
    border-radius: 0.6rem;
    height: 3.8rem;
    padding-top: 3vw;
    margin-top: 0.6rem;
    // border: 0.06rem solid rgba(0, 0, 0, 0.06);
  }
}

.card_tit {
  font-size: 1.1rem;
  font-weight: bold;
  padding: 2vh 0 3vh 3vw;
  display: flex;
  align-items: center;

  img {
    width: 2.5vw;
    height: 2.5vw;
    margin-right: 1vw;
  }
}

.col {
  text-align: center;
  background-color: #e4e4e4;
  padding: 0.1rem;
  border-top-right-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
  font-size: 0.7rem;
  padding: 0.3rem 0 0.3rem 0;
  color: #4e4f4e;
  float: right;
  margin-top: -15%;
  padding: 1.3vw;
}

.sanjiao {
  width: 0;
  height: 0;
  border-top: 0.45rem solid #c1c1c1;
  border-left: 0.4rem solid transparent;
  border-right: 0.4rem solid transparent;
  position: absolute;
  left: 36vw;
  top: 13.5vw;
  border-radius: 0.35rem;
}

.card .list {
  height: 30vh;
  // overflow: auto;
}

.van-cell--clickable {
  width: 43vw;
  height: 10vw;
  background: #f2f2f2;
  line-height: 3vw;
  border-radius: 0.3rem;
}

/deep/ input.van-field__control {
  color: #777;
  font-size: 0.98rem;
}

.nohave {
  display: none;
  z-index: 2;
  position: relative;
}

.change {
  float: right;
  top: 1.5vw;
  left: 84vw;
}

/deep/ .van-cell.van-cell--clickable.van-field {
  display: inline-block;
  margin-top: 0.85rem;
}

/deep/ .van-tabs--line .van-tabs__wrap {
  height: 5rem;
}

/deep/ .van-tab__text--ellipsis {
  height: 100%;
  width: 100%;
  text-align: center;
}

.tit {
  width: 100%;
  overflow: hidden;
  margin-bottom: -1.2vh;
  font-size: 3.5vw;
  color: #999;
}

.txt {
  font-size: 5.5vw;
  color: #999;
  font-weight: 500;
}

/deep/.van-tab--active .txt {
  color: #333;
}

/deep/.van-popover__action {
  width: 205px;
  height: 35px;
  padding: 0 16px;
  font-size: 0.9rem;
  line-height: 35px;
  cursor: pointer;
}

/deep/.van-tabs__nav {
  align-items: center;
}

.notice {
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  // padding-top: 13px;

  .notice_text {
    position: absolute;
    right: 15px;
    top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .titles {
      font-size: 10px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }

    .icon {
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      font-size: 20px;
    }
  }
}
</style>
