var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vant-table"},[_c('table',{staticClass:"table",attrs:{"cellspacing":"0"}},[_c('tr',{staticStyle:{"position":"sticky"}},_vm._l((_vm.option.column),function(item,index){return _c('th',{key:index,staticClass:"th"},[_vm._v(" "+_vm._s(item.label)+" ")])}),0),_vm._l((_vm.tableData),function(item,index){return _c('tr',{key:index,staticClass:"list-tr"},_vm._l((_vm.option.column),function(context,i){return _c('td',{key:i},[(item[context.tableDataprop] !== null &&
          context.tableDataprop !== 'user_name'
          )?[_vm._v(" "+_vm._s(item[context.tableDataprop])+" ")]:_vm._e(),(item[context.tableDataprop] &&
          context.tableDataprop == 'user_name' &&
          item[context.tableDataprop].length >= 4
          )?[_vm._v(" "+_vm._s(item[context.tableDataprop].slice(0, 3))+"... ")]:_vm._e(),(item[context.tableDataprop] &&
          context.tableDataprop == 'user_name' &&
          item[context.tableDataprop].length <= 3
          )?[_vm._v(" "+_vm._s(item[context.tableDataprop])+" ")]:_vm._e(),((context.tableDataprop === 'attendance_time' ||
            context.tableDataprop === 'temperature') &&
          item.status_str === 9
          )?[_c('span',{staticClass:"xian"})]:_vm._e(),(context.tableDataprop === 'attendance_time' &&
          item.status_str === 3
          )?_c('img',{attrs:{"src":require("../../src/assets/img/note_xuexiao@2x.png"),"alt":""}}):_vm._e(),(context.tableDataprop === 'attendance_time' &&
          item.status_str === 2
          )?_c('img',{attrs:{"src":require("../../src/assets/img/note_xuexiaox@2x.png"),"alt":""}}):_vm._e()],2)}),0)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }