组件
<template>
  <div class="vant-table">
    <table cellspacing="0" class="table">
      <tr style="position: sticky">
        <th class="th" v-for="(item, index) in option.column" :key="index">
          {{ item.label }}
        </th>
      </tr>
      <tr v-for="(item, index) in tableData" :key="index" class="list-tr">
        <td v-for="(context, i) in option.column" :key="i">
          <template v-if="item[context.tableDataprop] !== null &&
            context.tableDataprop !== 'user_name'
            ">
            {{ item[context.tableDataprop] }}
          </template>
          <template v-if="item[context.tableDataprop] &&
            context.tableDataprop == 'user_name' &&
            item[context.tableDataprop].length >= 4
            ">
            {{ item[context.tableDataprop].slice(0, 3) }}...
          </template>
          <template v-if="item[context.tableDataprop] &&
            context.tableDataprop == 'user_name' &&
            item[context.tableDataprop].length <= 3
            ">
            {{ item[context.tableDataprop] }}
          </template>
          <template v-if="(context.tableDataprop === 'attendance_time' ||
              context.tableDataprop === 'temperature') &&
            item.status_str === 9
            ">
            <span class="xian"></span>
          </template>
          <img src="../../src/assets/img/note_xuexiao@2x.png" alt="" v-if="context.tableDataprop === 'attendance_time' &&
            item.status_str === 3
            " />
          <img src="../../src/assets/img/note_xuexiaox@2x.png" alt="" v-if="context.tableDataprop === 'attendance_time' &&
            item.status_str === 2
            " />
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name: "TableVant",
  props: {
    tableData: {
      type: Array,
      function() {
        return [];
      },
    },
    option: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  filters: {
    // getWeidao:function (value) {
    //   if(value==''){
    //     return
    //   }
    // }
    getStatus: function (data) {
      if (data === "attendance_time") {
        return 1;
      }
      return item[data];
    },
  },
  methods: {
    // getClass(i){
    //   if(i===9){
    //     return 'center'
    //   }
    // }
  },
};
</script>

<style scoped>
table {
  table-layout: fixed;
}

.vant-table {
  /* margin-bottom:2rem; */
  width: 100%;
  margin-top: 1.3rem;
  background: #fff;
  box-shadow: 0rem 0.13rem 0.31rem 0rem rgba(119, 119, 119, 0.06);
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  padding-bottom: 2rem;
}

.table {
  border-radius: 0.185185rem;
}

.th {
  font-size: 0.95rem;
  color: #999;
  font-weight: 500;
  height: 3rem;
  line-height: 3rem;
  width: 23vw;
  /* padding-left: 1.4rem; */
  text-align: center;
  /* padding-left: 1.3rem; */
}

/* .th:nth-child(3){
  padding-left: 0.7rem;
} */
.list-tr {
  height: 2.074074rem;
  line-height: 2.074074rem;
  text-align: center;
}

td {
  font-size: 0.9rem;
  color: #777;
  font-weight: 500;
  /* padding-left: 1.2rem; */
}

/* td:nth-child(2){
  padding-left:1.2rem;

}
td:nth-last-child(1) {
  padding-left: 1.6rem;
} */
td:nth-last-child(2) {
  padding-left: 0;
  position: relative;
  /* padding-left: 0.45rem; */
}

td img {
  /* position: absolute; */
  width: 7.5vw;
  left: 1vw;
  margin-bottom: -1vw;
}

td:nth-last-child(1) .xian {
  display: inline-block;
  width: 0.75rem;
  height: 0.1rem;
  background: #ff795b;
  /* margin-left: 0.3rem; */
}

td:nth-last-child(2) .xian {
  display: inline-block;
  width: 0.75rem;
  height: 0.1rem;
  background: #ff795b;
  /* margin-left: 1.8rem; */
}

/* .center{
  text-align: center;
} */
</style>
